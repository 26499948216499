import { graphql } from "gatsby";
import React from "react";
import EventList from "../components/EventList";
import ArticleList from "../components/ArticleList";
import IdeaList from "../components/IdeaList/IdeaList";
import SiteMetadata from "../components/SiteMetadata";
import Layout from "../layouts/Layout";
import Hero from "../components/Hero";

export default function PersonPage(props) {
  const personName = props.data.item.childContentfulPersonTitleTextNode.title;

  return (
    <Layout>
      <SiteMetadata
        title={`Tolle Geschenkideen für ${personName} - Tipps & Anleitungen 🤩`}
        description={`Viele tolle Geschenkideen für ${personName}! Bereite deiner Lieblingsperson eine Freude.`}
        image={`${props.data.item.icon.file.url}`}
      />

      <Hero image={props.data.item.image[0].gatsbyImageData}>
        <h2 className="my-36 h1 leading-tight tracking-tight text-center">
          {props.data.item.childContentfulPersonTitleTextNode.title}
          <span className="text-yellow-500">.</span>
          <div className="text-xl sm:text-3xl text-gray-100 mt-4">
            Ideen für Deinen Liebling.
          </div>
        </h2>
      </Hero>

      <ArticleList person={personName} />

      <IdeaList person={personName} />
      <EventList items={props.data.events.nodes} />
    </Layout>
  );
}

export const query = graphql`
  query PersonQuery($title: String!) {
    item: contentfulPerson(title: { title: { eq: $title } }) {
      childContentfulPersonTitleTextNode {
        title
      }
      icon {
        file {
          url
        }
      }
      image {
        gatsbyImageData
      }
    }
    events: allContentfulAnlass(sort: { order: ASC, fields: date }) {
      nodes {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        title
      }
    }
  }
`;
